var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('subscriptionsOperations.subscriptionsOperations'),"description":_vm.$t('subscriptionsOperations.FromHereYouCanControlsubscriptionsOperations')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('subscriptionsOperations.SearchForAnOperation'),"emptyTableText":_vm.$t('subscriptionsOperations.ThereAreNoOperations'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.subscriptions_operations_add,"statusSearch":true,"statusValues":[
          { title: _vm.$t('quotations.situation'), value: '' },
          { title: _vm.$t('quotations.all'), value: '' },
          { title: _vm.$t('subscriptionsOperations.draft'), value: 0 },
          { title: _vm.$t('subscriptionsOperations.sent'), value: 1 },
          { title: _vm.$t('subscriptionsOperations.seen'), value: 2 },
          { title: _vm.$t('subscriptionsOperations.driven'), value: 3 },
          { title: _vm.$t('subscriptionsOperations.canceled'), value: 4 } ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('subscriptionsOperations.Code'),
            type: 'mainLink',
            sort: true,
          },
          {
            column: 'date',
            title: _vm.$t('subscriptionsOperations.OperationDate'),
            type: 'text',
            sort: true,
          },
          {
            column: 'client_type',
            title: _vm.$t('subscriptionsOperations.customerName'),
            type: 'clintLink',
            sort: false,
          },
          {
            column: 'total',
            title: _vm.$t('subscriptionsOperations.Cost'),
            type: 'text',
            sort: true,
          },
          {
            column: 'rest',
            title: _vm.$t('subscriptionsOperations.balance'),
            type: 'text',
            sort: true,
          },
          {
            column: 'status',
            title: _vm.$t('subscriptionsOperations.Situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('subscriptionsOperations.draft'), value: 0, color: 'dark' },
              { title: _vm.$t('subscriptionsOperations.sent'), value: 1, color: 'primary' },
              { title: _vm.$t('subscriptionsOperations.seen'), value: 2, color: 'primary' },
              {
                title: _vm.$t('subscriptionsOperations.driven'),
                value: 3,
                color: 'success',
              },
              {
                title: _vm.$t('subscriptionsOperations.canceled'),
                value: 4,
                color: 'danger',
              } ],
          },
          {
            column: 'options',
            title: _vm.$t('subscriptionsOperations.Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$user.admin || _vm.$user.role.subscriptions_operations_edit,
              },
              {name: 'invoiceLink',   role: _vm.$user.admin || _vm.$user.role.sale_returns_add, title: _vm.$t('subscriptionsOperations.ReturnSale'), icon: 'exchange', link:'/saleReturns/create?invoice_id='},
              { name: 'outLink', role: true },
              { name: 'printInvoice', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.subscriptions_operations_delete,
              } ],
          } ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem:
            _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading'),
        }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }