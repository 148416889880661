<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('subscriptionsOperations.subscriptionsOperations')"
          :description="$t('subscriptionsOperations.FromHereYouCanControlsubscriptionsOperations')"
        />
        <indexTable
          :searchText="$t('subscriptionsOperations.SearchForAnOperation')"
          :emptyTableText="$t('subscriptionsOperations.ThereAreNoOperations')"
          :emptyTableSubText="
            $t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')
          "
          :buttonRole="$user.role.subscriptions_operations_add"
          :statusSearch="true"
          :statusValues="[
            { title: $t('quotations.situation'), value: '' },
            { title: $t('quotations.all'), value: '' },
            { title: $t('subscriptionsOperations.draft'), value: 0 },
            { title: $t('subscriptionsOperations.sent'), value: 1 },
            { title: $t('subscriptionsOperations.seen'), value: 2 },
            { title: $t('subscriptionsOperations.driven'), value: 3 },
            { title: $t('subscriptionsOperations.canceled'), value: 4 },
          ]"
          :cloumns="[
            {
              column: 'code',
              title: $t('subscriptionsOperations.Code'),
              type: 'mainLink',
              sort: true,
            },
            {
              column: 'date',
              title: $t('subscriptionsOperations.OperationDate'),
              type: 'text',
              sort: true,
            },
            {
              column: 'client_type',
              title: $t('subscriptionsOperations.customerName'),
              type: 'clintLink',
              sort: false,
            },
            {
              column: 'total',
              title: $t('subscriptionsOperations.Cost'),
              type: 'text',
              sort: true,
            },
            {
              column: 'rest',
              title: $t('subscriptionsOperations.balance'),
              type: 'text',
              sort: true,
            },
            {
              column: 'status',
              title: $t('subscriptionsOperations.Situation'),
              type: 'status',
              sort: true,
              values: [
                { title: $t('subscriptionsOperations.draft'), value: 0, color: 'dark' },
                { title: $t('subscriptionsOperations.sent'), value: 1, color: 'primary' },
                { title: $t('subscriptionsOperations.seen'), value: 2, color: 'primary' },
                {
                  title: $t('subscriptionsOperations.driven'),
                  value: 3,
                  color: 'success',
                },
                {
                  title: $t('subscriptionsOperations.canceled'),
                  value: 4,
                  color: 'danger',
                },
              ],
            },
            {
              column: 'options',
              title: $t('subscriptionsOperations.Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                {
                  name: 'edit',
                  role: $user.admin || $user.role.subscriptions_operations_edit,
                },
                {name: 'invoiceLink',   role: $user.admin || $user.role.sale_returns_add, title: $t('subscriptionsOperations.ReturnSale'), icon: 'exchange', link:'/saleReturns/create?invoice_id='},
                { name: 'outLink', role: true },
                { name: 'printInvoice', role: true },
                { name: 'download', role: true },
                {
                  name: 'delete',
                  role: $user.admin || $user.role.subscriptions_operations_delete,
                },
              ],
            },
          ]"
          :deleteText="{
            attention: $t('allerts.Attention'),
            areYouReallySureToDeleteTheItem:
              $t('allerts.areYouReallySureToDeleteTheItem'),
            close: $t('allerts.close'),
            confirm: $t('allerts.confirm'),
            loading: $t('allerts.loading'),
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import indexHeader from "../elements/index/indexHeader.vue";
import indexTable from "../elements/index/indexTable.vue";

export default {
  data() {
    return {
      path: "/subscriptionsOperations",
      items: [],

      awaitingSearch: false,
      delete_id: null,
      totalPages: 0,
      loading: 0,

      params: {
        status: this.$route.params.status ? this.$route.params.status : "",
      },

      printInvoice: false,
    };
  },
  methods: {
    getItems() {
      this.loading = 1;
      this.totalPages = 0;
      this.items = [];
      this.$setParams(this.params);
      axios
        .get(this.$linkGnirator(this.path), { params: this.params })
        .then((response) => {
          this.items = response.data.data;
          if (this.$parent.localDatabase.subscriptionsOperations.length != 0) {
            var localItems = JSON.parse(
              JSON.stringify(this.$parent.localDatabase.subscriptionsOperations)
            );
            var items = localItems.concat(this.items);
            this.items = items;
          }
          this.totalPages = response.data.last_page;
          this.params.page = response.data.current_page;
          this.loading = 0;
          localStorage.subscriptionsOperationsCashe = JSON.stringify(response.data);
        });
    },
    deleteItem() {
      axios
        .post(
          this.$linkGnirator(this.path + "/" + this.items[this.delete_id].id),
          {
            _method: "DELETE",
          }
        )
        .then((response) => {
          this.getItems();
          this.delete_id = null;
          this.$parent.model = 0;
          return response;
        });
    },
  },
  watch: {
    "$parent.localDatabase.subscriptionsOperations": function () {
      this.getItems();
      if (localStorage.getItem("subscriptionsOperationsCashe") !== null) {
        this.loading = 0;
        var data = JSON.parse(localStorage.getItem("subscriptionsOperationsCashe"));
        this.items = data.data;
        this.totalPages = data.last_page;
        this.page = data.current_page;
      }
    },
    "params.search": function () {
      clearTimeout(this.awaitingSearch);
      this.awaitingSearch = setTimeout(() => {
        this.getItems();
      }, 400);
    },
    "params.status": function () {
      this.getItems();
    },
    items: function () {
      this.items.forEach(item => {
        item.date = this.$dateFormat(item.date)
        item.total = this.$moneyFormat(item.total)
        item.rest = this.$moneyFormat(item.rest)
        var customer = this.$database.customers.find(el => el.id == item.customer_id)
        item.customer = customer;
        var supplier = this.$database.suppliers.find(el => el.id == item.supplier_id)
        item.supplier = supplier;
      })
    }
  },
  mounted() {
    this.params = this.$getParams();
    this.params.status = this.params.status ? this.params.status : "";
    this.getItems();
    if (this.$route.params.id) {
      this.printInvoice = this.$route.params.id;
      this.$router.replace(this.path);
    }
    if (localStorage.getItem("subscriptionsOperationsCashe") !== null) {
      this.loading = 0;
      var data = JSON.parse(localStorage.getItem("subscriptionsOperationsCashe"));
      this.items = data.data;
      this.totalPages = data.last_page;
      this.page = data.current_page;
    }
    if (this.$parent.localDatabase.subscriptionsOperations.length != 0) {
      var localItems = JSON.parse(
        JSON.stringify(this.$parent.localDatabase.subscriptionsOperations)
      );
      var items = localItems.concat(this.items);
      this.items = items;
    }
  },
  components: {
    indexHeader,
    indexTable,
  },
};
</script>
